import ListicleOverviewLayout from './Loadable'


const LISTICLE_PAGES = {
  path: `/listicle`,
  exact: true,
  component: ListicleOverviewLayout,
  layoutConfig: {
    title: 'Listicle',
    routes: [{breadcrumbName: 'Listicle'}]
  }
}

export default LISTICLE_PAGES

import { call, put, takeLatest, select } from '@redux-saga/core/effects'
import {setNotify, setGlobalLoading} from 'appActions'
import {
  updateCompanyDetailsFetcher
} from 'fetchers/company.fetchers'

import * as types from './types'
import * as actions from './actions'
import * as stateManagerRoot from './../../store/stateManager'


function* watchSaveConfigAction({payload: {advanceConfig, onSuccess}}) {
  try {
    yield put(setGlobalLoading(true))
    const id = yield select(stateManagerRoot.getCompanyId)
    const response = yield call(updateCompanyDetailsFetcher, {
      id,
      ...advanceConfig
    })

    yield put(actions.saveConfigSuccess(response))
    onSuccess && onSuccess(response)
    setNotify('success', `Company updated successfully.`)
  } catch (e) {
    yield put(actions.saveConfigError(e))
    setNotify('error', e)
  } finally {
    yield put(setGlobalLoading(false))
  }
}

export default function* rootSaga() {
  yield takeLatest(types.SAVE_CONFIG, watchSaveConfigAction)
}

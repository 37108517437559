import privateFetcher from './privateFetcher'
import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'
import {pureRequest} from './request'
import _omitBy from 'lodash/fp/omitBy'
import _isNil from 'lodash/fp/isNil'


const getBaseUrl = () => `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/companies`
const getBaseUrlUsages = (version) => `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/${version ? version + '/' : ''}usages/companies`

export const getCompanyListFetcher = params => privateFetcher
  .get(`${getBaseUrl()}`, params)

export const deleteCompanyFetcher = (companyId) => privateFetcher
  .delete(`${getBaseUrl()}/${companyId}`)

export const getCompanyDetailsFetcher = (companyId) => privateFetcher
  .get(`${getBaseUrl()}/${companyId}`)

export const getCompanySummaryInfo = () => privateFetcher
  .get(`${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/summary/companies`)

export const updateCompanyDetailsFetcher = (companyDetails) => privateFetcher
  .put(`${getBaseUrl()}`, undefined, companyDetails)

export const updateCompanySubscriptionPlanFetcher = (params) => privateFetcher
  .put(`${getBaseUrl()}/plans`, undefined, params)

export const getUserListByCompaniesFetcher = (companyId, params) => privateFetcher
  .get(`${getBaseUrl()}/${companyId}/users`, params)

export const updateUserListByCompanyFetcher = (companyId, {newUsers, removedUsers}) => privateFetcher
  .put(`${getBaseUrl()}/${companyId}/users`, undefined, {
    newUsers,
    removedUsers
  })

export const addUserListByCompanyFetcher = (companyId, invitingUsers) => privateFetcher
  .post(`${getBaseUrl()}/${companyId}/users/invite`, undefined, {invitingUsers})

export function exportFile(params) {
  return pureRequest({
    url: `${getBaseUrl()}/csv`,
    params: _omitBy(_isNil, {...params}),
    method: 'GET'
  }).then((res) => res.blob().then(blob => {
    const filename = 'companies-report.csv'
    const newblob = new Blob([blob], {type: 'text/csv'})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(newblob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }))
}

export const syncToCrmSubscriptionFetcher = (companyId) => privateFetcher
  .post(`${getBaseUrl()}/plans/sync`, undefined, { id: companyId })
  
export const syncToCrmCompanyFetcher = (companyId) => privateFetcher
  .post(`${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/crm/migration/start`, undefined, {id: companyId})

export const getCompanyListUsages = params => {
  const { apiVersion, ...rest } = params
  return privateFetcher.get(`${getBaseUrlUsages(apiVersion)}`, rest)
}

export function exportFileUsages(params) {
  const { apiVersion, ...rest } = params
  return pureRequest({
    url: `${getBaseUrlUsages(apiVersion)}/csv`,
    params: _omitBy(_isNil, rest),
    method: 'GET'
  }).then((res) => res.blob().then(blob => {
    const filename = 'billing-usage.csv'
    const newblob = new Blob([blob], {type: 'text/csv'})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(newblob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }))
}

export const getBilingUsagesByCycle = params => {
  const { apiVersion, ...rest } = params
  return privateFetcher.get(`${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/billing/usages`, rest)
}
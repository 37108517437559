import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.INIT_DATA:
      return stateManager.initState()
    case types.RESET_DATA:
      return stateManager.resetState()
    case types.SET_COMPANY:
      return stateManager.updaterCompanyDetails(state, payload)
    case types.LOAD_DETAILS:
      return stateManager.updaterCompanyDetails(state, {isLoading: true})
    case types.LOAD_DETAILS_ERROR:
      return stateManager.updaterCompanyDetails(state, payload)
    case types.LOAD_DETAILS_SUCCESS:
      return stateManager.updaterCompanyDetails(state, payload)
    case types.UPDATE_DETAILS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.UPDATE_DETAILS_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.UPDATE_DETAILS_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.RESET_USERS:
      return stateManager.resetUserList(state)
    case types.LOAD_USERS:
      return stateManager.updaterUserList(state, {isLoading:true, ...payload})
    case types.LOAD_USERS_ERROR:
      return stateManager.updaterUserList(state, payload)
    case types.LOAD_USERS_SUCCESS:
      return stateManager.updaterUserList(state, payload)
    case types.ADD_USER:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.ADD_USER_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.ADD_USER_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.DELETE_USER:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.DELETE_USER_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.DELETE_USER_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.UPDATE_USER:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.UPDATE_USER_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.UPDATE_USER_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    default: return state;
  }
}

import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.INIT_DATA:
      return stateManager.initState()
    case types.RESET_DATA:
      return stateManager.resetState()
    case types.UPDATE_QUERY_STRING:
      return stateManager.updaterQueryString(state, payload)
    case types.LOAD_LIST_SUCCESS:
      return stateManager.updaterDataList(state, payload)
    case types.UPDATE_DATA_LIST_SETTINGS:
      return stateManager.updaterDataListSettings(state, payload)
    case types.UPDATE_DATE_RANGE:
      return stateManager.updateDateRange(state, payload);
    default: return state;
  }
}

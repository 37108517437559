import * as types from './types'

// Action Creators
export function initData() {
  return { type: types.INIT_DATA}
}

export function resetData() {
  return { type: types.RESET_DATA}
}

export function loadConfig() {
  return { type: types.LOAD_CONFIG}
}

export function loadConfigError(error) {
  return { type: types.LOAD_CONFIG_ERROR, payload: { error }}
}

export function loadConfigSuccess(response) {
  return { type: types.LOAD_CONFIG_SUCCESS, payload: { response }}
}

export function saveConfig(advanceConfig, onSuccess) {
  return { type: types.SAVE_CONFIG, payload:{advanceConfig, onSuccess}}
}

export function saveConfigError(error) {
  return { type: types.SAVE_CONFIG_ERROR, payload: { error }}
}

export function saveConfigSuccess(response) {
  return { type: types.SAVE_CONFIG_SUCCESS, payload: { response }}
}


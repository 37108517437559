import React from 'react'
import {default as Menu} from 'ctrwowShared/components/shared/Layout/MainMenu'
import {SIDEBAR_ITEMS} from './constants'


const MainMenu = ({isMini, className}) => (<Menu isSideBarMini={isMini} SIDEBAR_ITEMS={SIDEBAR_ITEMS} className={className} />)

export default MainMenu




import * as stateManager from 'appStateManager'
import React from 'react'

import {useSelector} from 'react-redux'

import MainHeader from './Header'

import Layout, {Content} from 'ctrwowShared/components/ui/Layout'
import LeftSide from './LeftSide'


const AppShellLayout = ({children}) => {

  const {disableMarginBody, isUsedAppShell=true} = useSelector(stateManager.getLayoutConfig) || {}


  return !isUsedAppShell ? children : (
    <Layout style={{minHeight: '100vh'}}>
      <LeftSide/>
      <Layout>
        <MainHeader/>
        <Content>
          <div className={disableMarginBody ? '' : 'dfoMar__base'}>
            {children}
          </div>
        </Content>
        {/*<Footer style={{textAlign: 'center'}}>CTRwow Admin &copy; {new Date().getFullYear()}</Footer>*/}
      </Layout>
    </Layout>
  )
}

AppShellLayout.propTypes = {}

export default AppShellLayout




import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'


export function getAuthenticateUrl() {
  return  `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_SSO_BASE_URL}?appid=${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_SSO_APP_ID}&callbackurl=${window.location.origin}`
}


export function getLogoutUrl() {
  return  `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_SSO_BASE_URL}/logout?appid=${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_SSO_APP_ID}`
}

import React from 'react'
import classNames from 'classnames'
import Dropdown from 'ctrwowShared/components/ui/Dropdown'
import UserName from './UserName'
import DropdownItems from './DropdownItems'

import styles from './UserInfo.module.scss'


const UserInfo = ({isMini}) => {
  return (
    <div className={classNames(styles.ctrComp, isMini ? styles['ctrComp--mini'] : null)}>
      <Dropdown
        overlay={<DropdownItems/>}
        placement="topLeft" arrow
      >

        <div className={styles.ctrComp__content}>
          <UserName isMini={isMini}/>
        </div>
      </Dropdown>
    </div>
  )
}

UserInfo.propTypes = {}

export default UserInfo




import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import SearchResult from "./SearchResult";
import injectStore, { actions } from "./store";

const BillingUsageOverview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadList());

    return () => dispatch(actions.resetData());
  }, [dispatch]);

  return <SearchResult />;

}

export default injectStore(BillingUsageOverview);
import CompanyDetailsLayout from './CompanyDetailsLayout.Loadable'


const COMPANY_PAGES = {
  path: `/company/:companyId`,
  exact: true,
  component: CompanyDetailsLayout,
  layoutConfig: {
    title: 'Company Details',
    routes: [
      {breadcrumbName: 'Companies', path: '/company'}
    ]
  }
}

export default COMPANY_PAGES

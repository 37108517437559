// this value will be used:
// - main subState to inject to rootState (name to inject dynamic reducer)
// - inject sub saga
// MODULE_STATE_NAME
import moment from 'moment';
export const MODULE_STATE_NAME = 'ctrAdminBillingUsage'

export const DEFAULT_END_DATE = moment.utc().endOf('day');
export const DEFAULT_START_DATE = moment.utc().startOf('day');


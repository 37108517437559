
import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SimpleSearchBox from 'ctrwowShared/components/shared/SimpleSearchBox'
import AddMember from './AddMember'
import {actions, stateManager} from './../store'



const FilterSection = () => {
  const dispatch = useDispatch()
  const companyId = useSelector(stateManager.getCompanyId)
  const onSearch = useCallback((queryString) => dispatch(actions.loadUsers({queryString})), [dispatch])
  const onAddUserSuccess = useCallback(() => dispatch(actions.loadUsers()), [dispatch])

  return (
    <div className={'dfoDis--flex'}>
      <SimpleSearchBox
        className={'dfoFlex--1'}
        onSearch={onSearch}
        placeholderId={'app.user.overview.searchbox.placeholder'}
      />
      <AddMember companyId={companyId} onSuccess={onAddUserSuccess}/>
    </div>
  )
}

FilterSection.propTypes = {}

export default FilterSection





import _isNumber from 'lodash/fp/isNumber'
import _toNumber from 'lodash/fp/toNumber'
import _cond from 'lodash/fp/cond'
import _T from 'lodash/fp/T'
import _constant from 'lodash/fp/constant'
import _pipe from 'lodash/fp/pipe'


export const currencyFormatter = (num, { locale = 'en-US', currency = 'USD', minimumFractionDigits = 0 } = {}) => _pipe([
  _toNumber,
  _cond([
    [
      _isNumber,
      num => num.toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: Number.isInteger(num) ? 0 : minimumFractionDigits
      })
    ],
    [_T, _constant('N/A')]
  ])
])(num);
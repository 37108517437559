import React, {useState, useCallback, useEffect} from 'react'
import {CloseCircleOutlined} from '@ant-design/icons'
import Select, {Option} from 'ctrwowShared/components/ui/Select'
import { getClaims } from 'fetchers/claim.fetchers'
import { setNotify } from "appActions";
import { DEFAULT_CLAIMS } from './constants';

const ClaimConfig = ({name, value, onChange, ...rest}) => {
  const [internalValue, set_internalValue] = useState(value || [])
  const [defaultSourceClaims, setDefaultSourceClaims] = useState(DEFAULT_CLAIMS)
  
  const onChangeCb = useCallback((value) => {
    set_internalValue(value)
    onChange && (name ? onChange(name, value) : onChange(value))
  }, [name, set_internalValue, onChange])

  useEffect(() => {
    getClaims()
      .then(res => {
        setDefaultSourceClaims(res)
      })
      .catch(e => {
        setNotify("error", e);
      })
  }, []);

  return <Select
    size={'large'}
    placeholder="Enter your CLAIMS strings"
    {...rest}
    mode="tags"
    value={internalValue}
    removeIcon={<CloseCircleOutlined/>}
    onChange={onChangeCb}>
    {defaultSourceClaims.map(item => <Option key={item}>{item}</Option>)}
  </Select>
}

ClaimConfig.propTypes = {}

export default ClaimConfig




import {useParams} from 'react-router-dom'
import FilterSection from './FilterSection'
import SearchResult from './SearchResultSection'
import {actions} from '../store'
import React, {useEffect} from 'react'

import {useDispatch} from 'react-redux'

const UserListLayout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.loadUsers())

    return () => dispatch(actions.resetUsers())
  }, [dispatch])

  return (
    <React.Fragment>
      <FilterSection/>
      <div className={'dfoMar__base--t'}>
        <SearchResult/>
      </div>
    </React.Fragment>
  )
}

UserListLayout.propTypes = {}

export default UserListLayout




import 'whatwg-fetch'
import _size from 'lodash/fp/size'
import {HeaderSetting, PreAuthHeaderSetting} from './setting'
import {responseHandler} from './request__commonHandlers'


function apiURL(option) {
  const specialCode = 'code=e0f06eaf-2a52-44d7-b461-d22395a46042'
  const queryString = option.params && _size(option.params) > 0 ? '?' + Object.keys(option.params).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(option.params[key]))
    .join('&') : ''
  return option.url + (queryString ? `${queryString}&${specialCode}` : `?${specialCode}`)
}

function apiWrapper(options) {
  const wrapper = {
    method: options.method,
    headers: options.form ?
      {
        Authorization: HeaderSetting.authenticate,
        ...options.headers
      } : {
        'Content-Type': 'application/json',
        Authorization: HeaderSetting.authenticate,
        ...options.headers
      }
  }
  return options.method === 'GET' ? {
    ...wrapper
  } : {
    ...wrapper,
    body: options.form ? options.form : JSON.stringify(options.data)
  }
}

function apiPreAuthWrapper(options) {
  const wrapper = {
    method: options.method,
    headers: options.form ?
      {'X_PREAUTH': PreAuthHeaderSetting.authenticate} :
      {
        'Content-Type': 'application/json',
        'X_PREAUTH': PreAuthHeaderSetting.authenticate
      }
  }
  return options.method === 'GET' ? {
    ...wrapper
  } : {
    ...wrapper,
    body: options.form ? options.form : JSON.stringify(options.data)
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }

  if (response.status >= 200 && response.status < 300) {
    // return response.json();
    return response.text().then(text => {
      try {
        return text ? JSON.parse(text) : {}
      } catch (e) {
        return {}
      }
    })
  }

  // eslint-disable-next-line no-throw-literal
  throw {
    status: response.status,
    statusText: response.statusText,
    response: response
  }
}

function timeoutPromise(promise, timeout, error) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(error)
    }, timeout)
    promise.then(resolve, reject)
  })
}

function fetchTimeout(url, options, timeout = 1000 * 60 * 15, error) {
  return timeoutPromise(fetch(url, options || {}), timeout, error || 'Request Timeout')
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(options) {
  const url = apiURL(options)
  const wrapper = apiWrapper(options)
  return fetchTimeout(url, wrapper, options.timeOut)
    .then((response) => responseHandler(options, response))
    .then(parseJSON)

}

/**
 * Requests a URL, returning a promise
 *
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function preAuthRequest(options) {
  const url = apiURL(options)
  const wrapper = apiPreAuthWrapper(options)
  return fetchTimeout(url, wrapper, options.timeOut).then(parseJSON)
}

export function pureRequest(options) {
  const url = apiURL(options)
  const wrapper = apiWrapper(options)
  return fetchTimeout(url, wrapper, options.timeOut);
}
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {object} [params] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const sendPureRequest = (url, params, options) => fetchTimeout(apiURL({url, params}), options).then(parseJSON)

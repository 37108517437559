import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.INIT_DATA:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.RESET_DATA:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.LOAD_CONFIG:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.LOAD_CONFIG_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.LOAD_CONFIG_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.SAVE_CONFIG:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.SAVE_CONFIG_ERROR:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    case types.SAVE_CONFIG_SUCCESS:
      // Perform action
      // return stateManager.updater(state, payload)
      return state
    default: return state;
  }
}
import React, { useEffect, useState } from "react";
import Card from "ctrwowShared/components/ui/Card";
import { Row, Col } from "ctrwowShared/components/ui/Grid";
import Select, { Option } from "ctrwowShared/components/ui/Select";

import FilterSection from "./FilterSection";
import ExportDataButton from "./ExportData";
import BillingUsageOverview from "./BillingUsageOverview";

import FilterSectionOld from "../BillingUsageOverviewOld/FilterSection";
import ExportDataButtonOld from "../BillingUsageOverviewOld/ExportData";
import BillingUsageOverviewOld from "../BillingUsageOverviewOld";

const PLANS = { OLD: "old-plan", NEW: "new-plan" };

const PlanSelector = ({ value, onChange }) => {
  const handleChange = (value) => {
    onChange && onChange(value);
  };

  return (
    <Select onChange={handleChange} value={value} style={{ width: "150px" }}>
      <Option value={PLANS.OLD}>Old Plan</Option>
      <Option value={PLANS.NEW}>New Plan</Option>
    </Select>
  );
};

const BillingUsageOverviewLayout = () => {
  const [activePlans, setActivePlans] = useState(PLANS.NEW);

  const PlanFilter = ({ activePlans }) => {
    return activePlans === PLANS.OLD ? <FilterSectionOld /> : <FilterSection />;
  };

  const ExportButton = ({ activePlans }) => {
    return activePlans === PLANS.OLD ? (
      <ExportDataButtonOld />
    ) : (
      <ExportDataButton />
    );
  };

  const MainContent = ({ activePlans }) => {
    return activePlans === PLANS.OLD ? <BillingUsageOverviewOld /> : <BillingUsageOverview />;
  };

  return (
    <Row gutter={16}>
      <Col span={24} style={{ transition: "0.2s" }}>
        <PlanFilter activePlans={activePlans} />
        <Card
          className={"dfoMar__base--t"}
          title={<PlanSelector onChange={setActivePlans} value={activePlans} />}
          extra={<ExportButton activePlans={activePlans} />}
        >
          <MainContent activePlans={activePlans} />
        </Card>
      </Col>
    </Row>
  );
};

export default BillingUsageOverviewLayout

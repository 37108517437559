import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import SimpleSearchBox from 'ctrwowShared/components/shared/SimpleSearchBox'
import DateFilter from "./DateFilter";
import {actions} from './../store'

const FilterSection = () => {
  const dispatch = useDispatch()
  const onSearch = useCallback((queryString) => dispatch(actions.updateQueryString(queryString)), [dispatch])

  return (
    <div className={'dfoDis--flex'}>
      <DateFilter />
      <SimpleSearchBox
        className={'dfoFlex--1'}
        onSearch={onSearch}
        placeholderId={'app.company.overview.searchbox.placeholder'}
      />
    </div>
  )
}

FilterSection.propTypes = {}

export default FilterSection

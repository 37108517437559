import {setLocale} from 'yup'
import messages from 'translations/common-validation.messages'
import _transformFp from 'lodash/fp/transform'
import _reduce from 'lodash/fp/reduce'
import _setFp from 'lodash/fp/set'
// convert to mutable
const _set = _setFp.convert({'immutable': false})
const _transform = _transformFp.convert({'cap': false})


const parsedMessages = _transform((validationSchema, {id, defaultMessage}, key) => {
  validationSchema.push([
    key.split('_'),
    id,
    defaultMessage
  ])
  return validationSchema

}, [], messages)



function resetValidationMessages() {
  const schema = _reduce((acc, [paths, messageId, defaultMessage ]) => {
    return _set(paths, defaultMessage, acc)
  }, {}, parsedMessages)
  setLocale(schema);
}
resetValidationMessages()

import React, {useState, useCallback} from 'react'
import {CloseCircleOutlined} from '@ant-design/icons'
import Select, {Option} from 'ctrwowShared/components/ui/Select'

const Tags = ({name, value, onChange, ...rest}) => {
  const [internalValue, set_internalValue] = useState(value || [])
  const onChangeCb = useCallback((value) => {
    set_internalValue(value)
    onChange && (name ? onChange(name, value) : onChange(value))
  }, [name, set_internalValue, onChange])

  return <Select
    size={'large'}
    placeholder="Enter your Tags strings"
    {...rest}
    mode="tags"
    value={internalValue}
    removeIcon={<CloseCircleOutlined/>}
    onChange={onChangeCb}>
    {internalValue.map(item => <Option key={item}>{item}</Option>)}
  </Select>
}

Tags.propTypes = {}

export default Tags




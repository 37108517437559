import privateFetcher from './privateFetcher'
import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'

const getBaseUrl = () => `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/claims`

export const getClaims = async () => {
  const cachedClaims = localStorage.getItem('claims')
  const cachedClaimsExpireTime = localStorage.getItem('claimsExpireTime')

  if (cachedClaims && cachedClaimsExpireTime && Date.now() < Number(cachedClaimsExpireTime)) {
    return JSON.parse(cachedClaims)
  }

  const response = await privateFetcher.get(`${getBaseUrl()}`)
  localStorage.setItem('claims', JSON.stringify(response))

  // Set the expire time to 1 hour from now
  const expireTime = Date.now() + (60 * 60 * 1000)
  localStorage.setItem('claimsExpireTime', expireTime.toString())

  return response
}

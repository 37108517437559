// import ExternalLanderLayout from './ExternalLanderLayout.Loadable'
// import TemplateOverviewLayout from './TemplateOverviewLayout'
import TemplateOverviewLayout from './TemplateOverviewLayout.Loadable'


const TemplateOverview = [
  {
    path: `/template`,
    exact: true,
    component: TemplateOverviewLayout,
    layoutConfig: {
      title: 'Templates',
      routes: [{breadcrumbName: 'Templates'}]
    }
  },
  {
    path: `/email-template`,
    exact: true,
    component: TemplateOverviewLayout,
    layoutConfig: {
      title: 'Email Templates',
      routes: [{breadcrumbName: 'Email Templates'}]
    }
  }
]

export default TemplateOverview

import React from 'react'
import _values from 'lodash/fp/values'
import _find from 'lodash/fp/find'
import _get from 'lodash/fp/get'
import _pipe from 'lodash/fp/pipe'
import { dateFormatter } from 'ctrwowShared/utils/dateFormatter'
import { PACKAGES, SUBSCRIPTION_PLAN_TYPE } from "../../constants";

const getPlanName = (planId) => {
  for (let item of Object.values(PACKAGES)) {
    if (item.value === planId) {
      if (!item.groupName) return item.name
      if (item.groupName === "Base") return `${item.groupName} Plan - ${item.name} Package`
      return `${item.groupName} - ${item.name} Package`
    }
  }
  return "";
}

const NextPlanInfo = ({subscriptionInfo: {nextChargeDate, nextPlanId, nextSubscriptionType, currentPlanId }}) => {
  const currentPlanName = getPlanName(currentPlanId)

  if (!nextChargeDate) {
      return null
  }

  if (nextPlanId && nextPlanId !== currentPlanId) {
      const nextSubTypeName = nextPlanId !== PACKAGES.BRONZE.value ? SUBSCRIPTION_PLAN_TYPE[nextSubscriptionType] : ''
      const nextSubPackageName = _pipe([_values, _find({value: nextPlanId}), _get('name')])(PACKAGES)
      return <p>Company's plan will change to <strong>{nextSubPackageName}{nextSubTypeName ? `-${nextSubTypeName}` : ''}</strong> on <strong>{dateFormatter(nextChargeDate)}</strong></p>
  }

  return <p>Your Next Billing Date: <strong>{currentPlanName}</strong> - <strong>{dateFormatter(nextChargeDate)}</strong></p>
}

const SubscriptionDetail = ({ company }) => {
  const currentPlanId = _get('subscription.currentPlanId', company);
  const trackingAddOnPlanId = _get('trackingSubscription.currentPlanId', company);
  const splitTestAddOnPlanId = _get('splittestSubscription.currentPlanId', company);

  const currentPlan = getPlanName(currentPlanId)
  const trackingAdOnPlan = getPlanName(trackingAddOnPlanId)
  const splitTestAddOnPlan = getPlanName(splitTestAddOnPlanId)
  const addOns = [trackingAdOnPlan, splitTestAddOnPlan].filter(item => item)

  return <div>
    <div><span className='bold'>{ currentPlan }</span></div>
    { addOns.length > 0 ? <div>
      <div>Add-ons:</div>
      <ul>
        { addOns.map((addOn, index) => <li key={index}><span className='bold'>{ addOn }</span></li>) }
      </ul>
    </div> : null }

    <NextPlanInfo subscriptionInfo={currentPlan} />
    <NextPlanInfo subscriptionInfo={trackingAdOnPlan} />
    <NextPlanInfo subscriptionInfo={splitTestAddOnPlan} />
  </div>
}

export default SubscriptionDetail
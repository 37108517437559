import {call, put, takeLatest, select} from '@redux-saga/core/effects'
import {setNotify, setGlobalLoading} from 'appActions'
import {
  getUserListByCompaniesFetcher, updateUserListByCompanyFetcher,
  deleteCompanyFetcher, getCompanyDetailsFetcher, updateCompanyDetailsFetcher
} from 'fetchers/company.fetchers'

import {updateRoleUserFetcher} from 'fetchers/user.fetcher'

import * as types from './types'
import * as actions from './actions'
import * as stateManager from './stateManager'


function* sendLoadUserRequest() {
  try {
    const companyId = yield select(stateManager.getCompanyId)
    const params = yield select(stateManager.getUserListConfigParams)
    const users = yield call(getUserListByCompaniesFetcher, companyId, params)
    yield put(actions.loadUsersSuccess(users))

  } catch (e) {
    yield put(actions.loadUsersError(e))
  }
}

function* sendDeleteCompanyRequest({payload: {company, onDeleteSuccess}}) {
  try {
    console.log(company)
    const response = yield call(deleteCompanyFetcher, company.id)

    yield put(actions.deleteCompanySuccess(response))
    onDeleteSuccess && onDeleteSuccess(company)
    setNotify('success', `Company deleted successfully.`)
  } catch (e) {
    yield put(actions.deleteCompanyError(e))
  }
}

function* sendUpdateCompanyRequest({payload: {companyDetails, onSuccess}}) {
  try {
    yield put(setGlobalLoading(true))
    const response = yield call(updateCompanyDetailsFetcher, companyDetails)

    yield put(actions.updateDetailsSuccess(response))
    onSuccess && onSuccess(companyDetails)
    setNotify('success', `Company updated successfully.`)

  } catch (e) {
    yield put(actions.updateDetailsError(e))
    setNotify('error', e)
  } finally {
    yield put(setGlobalLoading(false))
  }
}

function* sendLoadCompanyDetailsRequest() {
  try {
    const companyId = yield select(stateManager.getCompanyId)
    const companyDetails = yield call(getCompanyDetailsFetcher, companyId)
    yield put(actions.loadDetailsSuccess(companyDetails))
  } catch (e) {
    yield put(actions.loadDetailsError(e))
  }
}

function* sendDeleteUserRequest({payload: {user}}) {
  console.log(user)
  try {
    yield put(setGlobalLoading(true))
    const companyId = yield select(stateManager.getCompanyId)
    const companyDetails = yield call(updateUserListByCompanyFetcher, companyId, {removedUsers: [user.id]})
    yield put(actions.deleteUserSuccess(companyDetails))
    setNotify('success', `User deleted successfully.`)
  } catch (e) {
    yield put(actions.deleteUserError(e))
  } finally {
    yield put(setGlobalLoading(false))
  }
}

function* sendUpdateUserRequest({payload: {userDetails: {id, ...rest}, onSuccess}}) {
  try {
    yield put(setGlobalLoading(true))
    const companyId = yield select(stateManager.getCompanyId)
    const userDetails = yield call(updateRoleUserFetcher, {
      userId: id,
      params: [{
        ...rest,
        companyId
      }]
    })
    yield put(actions.updateUserSuccess(userDetails))
    onSuccess && onSuccess()
    setNotify('success', `User updated successfully.`)
  } catch (e) {
    yield put(actions.updateDetailsError(e))
    setNotify('error', e)
  } finally {
    yield put(setGlobalLoading(false))
  }
}

export default function* rootSaga() {
  yield takeLatest([
    types.LOAD_USERS,
    types.DELETE_USER_SUCCESS,
    types.ADD_USER_SUCCESS,
    types.UPDATE_USER_SUCCESS
  ], sendLoadUserRequest)

  yield takeLatest(types.DELETE_USER, sendDeleteUserRequest)
  yield takeLatest(types.UPDATE_USER, sendUpdateUserRequest)

  yield takeLatest([
    types.LOAD_DETAILS,
    types.UPDATE_DETAILS_SUCCESS
  ], sendLoadCompanyDetailsRequest)
  yield takeLatest(types.UPDATE_DETAILS, sendUpdateCompanyRequest)
  yield takeLatest(types.DELETE_COMPANY, sendDeleteCompanyRequest)
}

import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  featureConfigs: Yup.string().nullable().test('featureConfigs', 'Must be a valid json string', (value) => {
    if (!value || value.length < 1) {
      return true
    }

    try {
      const jsonFormated = JSON.parse(value)
      return jsonFormated ? true : false
    } catch(e) {
      return false
    }
  })
})

import _get from 'lodash/fp/get'
import {createSelector} from 'reselect'
import {MODULE_STATE_NAME, DEFAULT_START_DATE,DEFAULT_END_DATE} from './constants'
import produce from "immer";
import _pipe from "lodash/fp/pipe";
import _setFp from "lodash/fp/set";
import _trim from "lodash/fp/trim";
import {formatStartDateFilterTimeZoneStr, formatEndDateFilterTimeZoneStr} from "./date.utils"
const _set = _setFp.convert({'immutable': false})

const DEFAULT_TABLE_SETTINGS = {
  pagination: {
    current: 1,
    pageSize: 50
  },
  sorter: {},
}

const STATE_DEFAULT_VALUE = {
  filterValues: {
    queryString: '',
    tags: []
  },
  dateRange: [DEFAULT_START_DATE, DEFAULT_END_DATE],
  tableSettings: DEFAULT_TABLE_SETTINGS,
  dataList: [],
  uiState: undefined
}

export const initState = () => ({...STATE_DEFAULT_VALUE})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
export const getLocalState = (state) => _get([MODULE_STATE_NAME], state) || initState()

export const getQueryString = createSelector(
  getLocalState,
  _get(['filterValues', 'queryString'])
)

export const getSelectedTag = createSelector(
  getLocalState,
  _get(['filterValues', 'tags'])
)

export const getDateRanger = createSelector(
  getLocalState,
  _get(['dateRange'])
)

export const getTableSettings = createSelector(
  getLocalState,
  _get(['tableSettings'])
)

export const getDataListParams = createSelector(
  getQueryString,
  getSelectedTag,
  getTableSettings,
  getDateRanger,
  (queryString, tags = [], {pagination: {current, pageSize}, sorter: {columnKey, order}}, dateRanger) => ({
    filterValue: _trim(queryString),
    isAll: true,
    page: current - 1, count: pageSize,
    ...(tags && tags.length && {tags: tags.join(',')}),
    ...(order && {sortField: columnKey, isDesc: order === 'descend'}),
    fromDate: formatStartDateFilterTimeZoneStr(dateRanger[0]),
    toDate: formatEndDateFilterTimeZoneStr(dateRanger[1])
  })
)

export const getDataList = createSelector(
  getLocalState,
  _get('dataList')
)

/******************************** UPDATER ********************************/
const resetTableSettings = _set('tableSettings', DEFAULT_TABLE_SETTINGS)
export const updaterDataListSettings = (state, {tableSettings}) => produce(state, _set('tableSettings', tableSettings))
export const getCompanyUiStatus = createSelector(
  getLocalState,
  _get('uiState.loadDetails')
)
export const updaterFilterValue = (state, {key, value}) => produce(state, _pipe([_set(['filterValues', key], value), resetTableSettings]))
export const updaterQueryString = (state, {queryString}) => produce(
  state, _pipe([_set(['filterValues', 'queryString'], queryString), resetTableSettings]))
export const updaterDataList = (state, {dataList}) => produce(state, _set(['dataList'], dataList))
export const updateDateRange = (state, {dateRange}) => produce(state, _set(['dateRange'], dateRange))

import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {setNotify, setGlobalLoading} from 'appActions'
import {syncToCrmCompanyFetcher} from 'fetchers/company.fetchers'


export const useSyncToCrmAction = (companyId, onSuccess) => {
  const dispatch = useDispatch()

  return useCallback(async () => {
    try {
      dispatch(setGlobalLoading(true))
      await syncToCrmCompanyFetcher(companyId)
      onSuccess && (await onSuccess())
      dispatch(setNotify('success', 'The action is successful. The process will complete soon. Please check it again later.'))
    } catch(e) {
      dispatch(setNotify('error', e))
    }
    finally {
      dispatch(setGlobalLoading(false))
    }
  }, [companyId, dispatch, onSuccess])
}

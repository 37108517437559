import injectReducer from 'utils/injectReducer'
import reducer from './reducer'
import injectSaga from 'utils/injectSaga'
import saga from './saga'
import { compose } from 'redux'
import { MODULE_STATE_NAME } from './constants'

export * as actions from './actions'
export * as stateManager from './stateManager'

const withReducer = injectReducer({ key: MODULE_STATE_NAME, reducer })
const withSaga = injectSaga({ key: MODULE_STATE_NAME, saga })

const injectToStore = compose(
  withReducer,
  withSaga
)

export default injectToStore

import SchemaOverviewLayout from './SchemaOverviewLayout.Loadable'


const EXTERNAL_LANDER_PAGES = {
  path: `/schema`,
  exact: true,
  component: SchemaOverviewLayout,
  layoutConfig: {
    title: 'Schemas',
    routes: [{breadcrumbName: 'Schemas'}]
  }
}

export default EXTERNAL_LANDER_PAGES

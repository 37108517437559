import _groupBy from 'lodash/fp/groupBy'
import {ROUTES as DashboardRoutes} from './Dashboard'
import {ROUTES as WidgetRoutes} from './Widget'
import {ROUTES as SchemaRoutes} from './Schema'
import {ROUTES as TemplateRoutes} from './Template'
import {ROUTES as UserRoutes} from './User'
import {ROUTES as CompanyRoutes} from './Company'
import {ROUTES as AnnouncementRoutes} from './Announcements'
import {ROUTES as SystemRoutes} from './System'
import {ROUTES as ListicleRoutes} from './Listicle'
import {ROUTES as GlobalSearchRoutes} from './GlobalSearch'
import {ROUTES as PackageFeatures} from './PackageFeatures'
import {ROUTES as BillingUsageRoutes} from './BillingUsage'


const routes = [
  ...DashboardRoutes,
  ...WidgetRoutes,
  ...SchemaRoutes,
  ...TemplateRoutes,
  ...UserRoutes,
  ...CompanyRoutes,
  ...AnnouncementRoutes,
  ...SystemRoutes,
  ...ListicleRoutes,
  ...GlobalSearchRoutes,
  ...PackageFeatures,
  ...BillingUsageRoutes
]

export const modulesRoutes = _groupBy((({isPublic}) => isPublic ? 'publicPages' : 'privatePages'), routes)

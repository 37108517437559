import * as types from './types'
import {showToastMessage} from 'ctrwowShared/components/shared/ToastMessage'

// Action Creators
export function setGlobalLoading(isLoading) {
  return {type: types.SET_GLOBAL_LOADING, payload: {isLoading}}
}

export function setNotify(type, content) {
  showToastMessage(type, content)
  return {type: types.SET_NOTIFY, payload: {type, content}}
}

export function setLayoutConfig(layoutConfig) {
  return {type: types.SET_LAYOUT_CONFIG, payload: {layoutConfig}}
}

export function setCurrentUser(currentUser) {
  //TODO
  return {type: types.GET_CURRENT_USER, payload: {currentUser}}
}

//
// export function updateCurrentUser() {
//   //TODO
//   return {type: types.UPDATE_CURRENT_USER}
// }
//
// export function updateCurrentUserSuccess() {
//   //TODO
//   return {type: types.UPDATE_CURRENT_USER_SUCCESS}
// }
//
// export function updateCurrentUserError() {
//   //TODO
//   return {type: types.UPDATE_CURRENT_USER_ERROR}
// }

export function resetUser(callback) {
  return {type: types.RESET_USER, payload: {callback}}
}

export function setBreadcrumb(routes, isMerged=false) {
  return {type: types.SET_LAYOUT_CONFIG_BREADCRUMB, payload: {routes, isMerged}}
}

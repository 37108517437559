import {useDispatch, useSelector} from 'react-redux'
import {TABLE_COLUMNS} from './tableColumns'
import React, {useMemo, useCallback} from 'react'
import Table from 'ctrwowShared/components/ui/Table'
import {stateManager, actions} from './../store'
import styles from './SearchResultSection.module.scss'

const SearchResultSection = () => {
  const dispatch = useDispatch()
  const {results, quantity} = useSelector(stateManager.getDataList)
  const {pagination} = useSelector(stateManager.getTableSettings)
  const paginationConfig = useMemo(() => ({...pagination, total: quantity}), [pagination, quantity])
  const onChangeTableSettings = useCallback((pagination, filters, sorter) =>
    dispatch(actions.updateDataListSettings({pagination, filters, sorter})), [dispatch])
  return (
    <Table
      className={styles.ctrComp}
      columns={TABLE_COLUMNS}
      dataSource={results}
      pagination={paginationConfig}
      onChange={onChangeTableSettings}
      scroll={{x: 'max-content'}}
      rowKey='companyDomain'
      // loading={true}
    />
  )
}

SearchResultSection.propTypes = {}

export default SearchResultSection




import React, {useEffect, useState, useMemo, useCallback} from 'react'
import _get from 'lodash/fp/get'
import PropTypes from 'prop-types'
import {Link, useParams} from 'react-router-dom'
import 'ctrwowShared/components/ui/Tabs'
import Card from 'ctrwowShared/components/ui/Card'
import {useDispatch, useSelector} from 'react-redux'
import GeneralInfo from './GeneralInfo'
import UserList from './UserList'
import AdvanceSettings from './AdvanceSettings'
import MigrationCRM from './MigrationCRM'
import {getCompanyName} from 'ctrwowShared/domainData/company.domainData'
import {actions as appActions} from 'modules/AppCore'
import injectStore, {actions, stateManager} from './store'
import SubscriptionPlan from './SubscriptionPlan'
import {CloseCircleTwoTone} from '@ant-design/icons'

const CompanyDetailsLayout = ({className, onClose, company, ...rest}) => {
  const dispatch = useDispatch()
  const {companyId} = useParams()
  const hasCompany = useSelector(stateManager.getCompanyId)
  const companyDetailsFromState = useSelector(stateManager.getCompany)
  const [activeKey, set_activeKey] = useState('details')
  const [refresh, set_refresh] = useState(false)

  const tabList = useMemo(() => ({
    list: [
      { key: 'details', tab: 'General Information' },
      { key: 'subscriptionPlan', tab: 'Subscription Plan' },
      { key: 'users', tab: 'Users' },
      { key: 'advanceSettings', tab: 'Advanced Settings' },
      { key: 'migrationCRM', tab: 'Migration' },
    ],
    content: {
      details: GeneralInfo,
      users: UserList,
      advanceSettings: AdvanceSettings,
      subscriptionPlan: SubscriptionPlan,
      migrationCRM: MigrationCRM,
    }
  }), []);
  const TabContent = useMemo(() => tabList.content[activeKey], [tabList, activeKey])
  const companyName = useMemo(() => getCompanyName(companyDetailsFromState), [companyDetailsFromState])

  const loadCompanyDetails = useCallback(() => dispatch(actions.loadDetails()), [dispatch])

  useEffect(() => {
    dispatch(actions.initData())
    return () => dispatch(actions.resetData())
  }, [dispatch])

  useEffect(() => {
    if (companyId) {
      dispatch(actions.setCompany(null, companyId))
    } else if (company && company.id) {
      dispatch(actions.setCompany(company))
      set_refresh(true)
      setTimeout(() => set_refresh(false), 100)
    }

    if (!company || !company.id) {
      loadCompanyDetails()
    }
  }, [company, companyId, dispatch, loadCompanyDetails])

  useEffect(() => {
    companyName && dispatch(appActions.setBreadcrumb([{breadcrumbName: companyName}], true))
  }, [companyName, dispatch])


  return <Card
    className={className}
    title={company && company.id ? (<>Company Name: <Link to={`/company/${company.id}`}>{getCompanyName(company)}</Link></>) : null}
    extra={company && company.id ? <a href="#" onClick={onClose}><CloseCircleTwoTone /></a> : null}
    onTabChange={set_activeKey}
    activeTabKey={activeKey}
    tabList={tabList.list}
  >
    {hasCompany && !refresh && <TabContent {...rest}
                                           company={company || companyDetailsFromState}
                                           key={_get('updatedOn', company || companyDetailsFromState)}
                                           loadCompanyDetails={company ? undefined : loadCompanyDetails}/>}
  </Card>
}

CompanyDetailsLayout.propTypes = {
  company: PropTypes.any,
  className: PropTypes.any
}

export default injectStore(CompanyDetailsLayout)




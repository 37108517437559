import BillingLayout from './BillingLayout'
const ROUTES = [
  {
    path: `/billing-usage`,
    component: BillingLayout,
    layoutConfig: {
      title: 'Billing Usage',
      routes: [{breadcrumbName: 'Billing Usage'}]
    }
  }
]

export default ROUTES

import * as Yup from 'yup'


export const validationSchema = Yup.object().shape({
  name: Yup.string().min(10),
  userList: Yup.array().min(1)
    .of(Yup.object().shape({
      email: Yup.string().required().email()
    }))
})

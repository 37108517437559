import {call, put, select, takeLatest} from '@redux-saga/core/effects'
import {setNotify, setGlobalLoading} from 'appActions'
import { getCompanyListUsages, exportFileUsages } from 'fetchers/company.fetchers'
import * as types from './types'
import * as stateManager from "./stateManager"
import * as actions from "./actions"

function* watchGetList() {
  try {
    yield put(setGlobalLoading(true))
    const params = yield select(stateManager.getDataListParams)
    const response = yield call(getCompanyListUsages, {...params, apiVersion: 'v2' })
    yield put(actions.loadListSuccess(response))
  } catch (e) {
    setNotify('error', e)
  } finally {
    yield put(setGlobalLoading(false))
  }
}
function* watchExportData() {
  try {
    const params = yield select(stateManager.getDataListParams)
    yield put(setGlobalLoading(true))
    yield call(exportFileUsages, {...params, apiVersion: 'v2' })
  } catch (e) {
    console.log(e)
    setNotify('error', e)
  } finally {
    yield put(setGlobalLoading(false))
  }
}

export default function* rootSaga() {
  yield takeLatest([
    types.LOAD_LIST,
    types.UPDATE_QUERY_STRING,
    types.UPDATE_DATA_LIST_SETTINGS,
    types.UPDATE_DATE_RANGE
  ], watchGetList)
  yield takeLatest(types.EXPORT_COMPANY, watchExportData)
}

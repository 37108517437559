import React, {useEffect} from 'react'
import {useQuery} from 'ctrwowShared/hooks/useQuery'

import * as appStateManager from 'appStateManager'
import {useSelector, useDispatch} from 'react-redux'
import NoPermission from './NoPermission'
import {useLocation} from 'react-router'
import { push } from 'connected-react-router'
import injectStore, {actions} from './store'
import {getAuthenticateUrl} from './store/getAuthenticateUrl'
import Progressing from './Progressing'


const PrivatePage = ({children}) => {
  const isLogin = useSelector(appStateManager.isLogin)
  const dispatch = useDispatch()
  const location = useLocation()
  const query = useQuery()

  useEffect(() => {
    const token = query.get('token')
    if (isLogin) {
      if (token) {
        dispatch(push(location && location.pathname ? location.pathname : '/'))
      }
      return
    }

    if (!token) {
      window.location = getAuthenticateUrl()
    } else {
      dispatch(actions.authenticate(token))
    }
  }, [dispatch, isLogin, location, query])

  if (!isLogin) {
    return <Progressing />
  }

  if (isLogin === 'unauthorized') {
    return <NoPermission />
  }

  return children
}

PrivatePage.propTypes = {}

export default injectStore(PrivatePage)




import CtrDashboardIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrDashboardIcon'
import CtrPrototypeIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrPrototypeIcon'
import CtrWidgetIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrWidgetIcon'
import CtrAnalyticsIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrAnalyticsIcon'
import DfoUserManagementIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/DfoUserManagementIcon'
import CtrManageIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrManageIcon'
import CtrHelpDeskIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrHelpDeskIcon'
import CtrManageSearchIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrManageSearchIcon'
import CtrAnnouncementIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrAnnouncementIcon'
import CtrSettingIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrSettingIcon'
import CtrContentIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrContentIcon'


export const SIDEBAR_ITEMS = [
  {
    labelId: 'app.mainMenu.dashboard',
    iconType: CtrDashboardIcon,
    path: '/'
  },
  {
    labelId: 'app.mainMenu.globalSearch',
    iconType: CtrManageSearchIcon,
    path: '/global-search'
  },
  {
    labelId: 'app.mainMenu.manage',
    iconType: CtrManageIcon,
    key:'manage_group',
    children: [
      {
        labelId: 'app.mainMenu.manage.users',
        iconType: DfoUserManagementIcon,
        path: '/users'
      },
      {
        labelId: 'app.mainMenu.manage.companies',
        iconType: DfoUserManagementIcon,
        path: '/company'
      },
      {
        labelId: 'app.mainMenu.manage.globalEmailTemplate',
        iconType: CtrPrototypeIcon,
        path: '/email-template'
      },
      {
        labelId: 'app.mainMenu.manage.globalTemplate',
        iconType: CtrPrototypeIcon,
        path: '/template'
      },
      {
        labelId: 'app.mainMenu.manage.globalWidgets',
        iconType: CtrWidgetIcon,
        path: '/widget'
      },
      {
        labelId: 'app.mainMenu.manage.globalSchemas',
        iconType: CtrContentIcon,
        path: '/schema'
      },
      {
        labelId: 'app.mainMenu.manage.system',
        iconType: CtrSettingIcon,
        path: '/system'
      },
      {
        labelId: 'app.mainMenu.manage.listicle',
        iconType: CtrManageIcon,
        path: '/listicle'
      },
      {
        labelId: 'app.mainMenu.manage.packageFeatures',
        iconType: CtrManageIcon,
        path: '/package-features'
      }
    ]
  },

  {
    labelId: 'app.mainMenu.announcements',
    iconType: CtrAnnouncementIcon,
    path: '/announcements'
  },

  {
    labelId: 'app.mainMenu.reports',
    iconType: CtrAnalyticsIcon,
    key:'reports',
    children: [
      {
        labelId: 'app.mainMenu.manage.billingUsage',
        iconType: CtrContentIcon,
        path: '/billing-usage'
      }
    ]
  },

  {
    labelId: 'app.mainMenu.help',
    iconType: CtrHelpDeskIcon,
    key: 'actionMenu__help',
    fnAction: () => window.open('//support.ctrwow.com')
  }
]

import * as types from './types'

// Action Creators
export function initData(company) {
  return { type: types.INIT_DATA, payload:{company}}
}

export function resetData() {
  return { type: types.RESET_DATA}
}
export function loadList() {
  return { type: types.LOAD_LIST}
}

export function loadListSuccess(dataList) {
  return {type: types.LOAD_LIST_SUCCESS, payload: {dataList}}
}

export function updateQueryString(queryString) {
  return {type: types.UPDATE_QUERY_STRING, payload: {queryString}}
}
export function updateDataListSettings(tableSettings) {
  return {type: types.UPDATE_DATA_LIST_SETTINGS, payload: {tableSettings}}
}
export function updateDateRange(dateRange) {
  return { type: types.UPDATE_DATE_RANGE, payload: {dateRange} }
}
export function exportData() {
  return {type: types.EXPORT_COMPANY}
}


import SearchOverviewLayout from './SearchOverviewLayout.Loadable'


const SEARCH_PAGES = {
  path: `/global-search`,
  exact: true,
  component: SearchOverviewLayout,
  layoutConfig: {
    title: 'Global Search',
    routes: [{breadcrumbName: 'Search'}]
  }
}

export default SEARCH_PAGES

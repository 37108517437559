import PackageFeaturesOverviewLayout from './Loadable'

const PACKAGE_PAGES = {
  path: `/package-features`,
  exact: true,
  component: PackageFeaturesOverviewLayout,
  layoutConfig: {
    title: 'Packages Features',
    routes: [{breadcrumbName: 'Packages Features'}]
  }
}

export default PACKAGE_PAGES

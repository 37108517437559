import React from 'react'
import {useSelector} from 'react-redux'
import GlobalLoadingCommon from 'ctrwowShared/components/shared/GlobalLoading'
import {stateManager} from './store'


const GlobalLoading = () => {
  const isLoading = useSelector(stateManager.isLoading)

  return isLoading ? <GlobalLoadingCommon /> :  null
}

GlobalLoading.propTypes = {}

export default GlobalLoading





import privateFetcher from './privateFetcher';
import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'

const getBaseUrl = () => `${CONFIGURABLE_CONSTANTS.CTRWOW_ADMIN_BASE_URL}/users`

export const getUserListFetcher = params => privateFetcher.get(`${getBaseUrl()}`, params)

export const getCompaniesByUser = userId => privateFetcher.get(`${getBaseUrl()}/${userId}/companies`)

export const updateRoleUserFetcher = (payload) => privateFetcher.put(`${getBaseUrl()}/${payload.userId}/companies`, undefined, payload.params)

export const deleteUserFetcher = (userId) => privateFetcher.delete(`${getBaseUrl()}/${userId}`)

import React from 'react'
import _get from 'lodash/fp/get'
import _toNumber from 'lodash/fp/toNumber'
import _find from 'lodash/fp/find'
import {Text} from 'ctrwowShared/components/ui/Typography'
import {currencyFormatter as formatter } from 'utils/numberFormatter'
import * as companyDomainData from 'ctrwowShared/domainData/company.domainData'
import { FormattedMessage } from 'react-intl'
import {PRICING_PACKAGES} from 'ctrwowShared/constants/pricingPackages'


const getPackageName = (packageFeatures, id) =>
  _get("name", _find({ value: id }, packageFeatures)) || "No Subscription";

const currencyFormatter = (value) => formatter(value, { minimumFractionDigits: 4 })

export const TABLE_COLUMNS = [
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: 'Company Domain',
    dataIndex: 'companyDomain',
    key: 'companyDomain',
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (_, rowData) => currencyFormatter(_get('totalAmount', rowData) || 0),
  },
  {
    title: 'Base Fee',
    dataIndex: 'baseFee',
    key: 'baseFee',
    render: (_, rowData) => currencyFormatter(_get('baseFee', rowData) || 0),
  },
  // {
  //   title: 'Subscription ID',
  //   dataIndex: ['subscription', 'currentPlanId'],
  //   key: 'currentPlanId',
  //   render: (currentPlanId) =>  getPackageName(PRICING_PACKAGES, currentPlanId)
  // },
  {
    title: 'Seat Allocation Count',
    dataIndex: 'seatAllocationCount',
    key: 'seatAllocationCount',
    render: (_, rowData) => _toNumber(_get('seatAllocationCount', rowData) || 0) + ` seat(s) `,
  },
  {
    title: 'Seat Allocation Amount',
    dataIndex: 'seatAllocationOverAmount',
    key: 'seatAllocationOverAmount',
    render: (_, rowData) => currencyFormatter(_get('seatAllocationOverAmount', rowData) || 0),
  },
  {
    title: 'CMS Storage Size',
    dataIndex: 'storageSize',
    key: 'storageSize',
    render: (_, rowData) => _toNumber(_get('storageSize', rowData) || 0) + ` GB`,
  },
  {
    title: 'CMS Storage Amount',
    dataIndex: 'storageOverAmount',
    key: 'storageOverAmount',
    render: (_, rowData) => currencyFormatter(_get('storageOverAmount', rowData) || 0),
  },
  // {
  //   title: 'Tracking Subscription ID',
  //   dataIndex: ['trackingSubscription', 'currentPlanId'],
  //   key: 'trackingCurrentPlanId',
  //   render: (currentPlanId) =>  getPackageName(PRICING_PACKAGES, currentPlanId)
  // },
  {
    title: 'Tracking Addon Fee',
    dataIndex: 'trackingAddonFee',
    key: 'trackingAddonFee',
    render: (_, rowData) => currencyFormatter(_get('trackingAddonFee', rowData) || 0),
  },
  {
    title: 'Tracking Sessions',
    dataIndex:'trackingSessionCount',
    key: 'trackingSessionCount',
    render: (_, rowData) => _toNumber(_get('trackingSessionCount', rowData) || 0) + ` session(s)`,
  },
  {
    title: 'Tracking Amount',
    dataIndex: 'trackingSessionOverAmount',
    key: 'trackingSessionOverAmount',
    render: (_, rowData) => currencyFormatter(_get('trackingSessionOverAmount', rowData) || 0),
  },
  // {
  //   title: 'Splittest Subscription ID',
  //   dataIndex: ['splittestSubscription', 'currentPlanId'],
  //   key: 'splittestCurrentPlanId',
  //   render: (currentPlanId) =>  getPackageName(PRICING_PACKAGES, currentPlanId)
  // },
  {
    title: 'Splittest Addon Fee',
    dataIndex: 'splittestAddonFee',
    key: 'splittestAddonFee',
    render: (_, rowData) => currencyFormatter(_get('splittestAddonFee', rowData) || 0),
  },
  {
    title: 'Split Testing Sessions',
    dataIndex:'splittestSessionCount',
    key: 'splittestSessionCount',
    render: (_, rowData) => _toNumber(_get('splittestSessionCount', rowData) || 0) + ` session(s)`,
  },
  {
    title: 'Split Testing Amount',
    dataIndex: 'splittestSessionOverAmount',
    key: 'splittestSessionOverAmount',
    render: (_, rowData) => currencyFormatter(_get('splittestSessionOverAmount', rowData) || 0),
  }
];

export const TABLE_COLUMNS2 = [
  {
    title: <FormattedMessage id={'app.company.overview.table.column.name'}/>,
    key: 'companyName',
    sorter: true,
    render: companyDomainData.getCompanyName,
    width: '400px',
    fixed: 'left'
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.totalAmount'}/>,
    key: 'totalAmount',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => currencyFormatter(_get('totalAmount', rowData) || 0),
    sorter: true,
    width: '200px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.companyDomain'}/>,
    key: 'companyDomain',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => <Text>{_get('companyDomain', rowData)}</Text>,
    width: '300px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.CTRWowSeatAllocationCount'}/>,
    key: 'CTRWowSeatAllocation',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => _toNumber(_get('seatAllocationCount', rowData) || 0) + ` seat(s) `,
    width: '300px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.CTRWowSeatAllocationAmount'}/>,
    key: 'CTRWowSeatAllocation',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => currencyFormatter(_get('seatAllocationAmount', rowData) || 0),
    width: '300px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.databaseStorageSize'}/>,
    key: 'databaseStorage',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => _toNumber(_get('totalStorageSize', rowData) || 0) + ` GB`,
    width: '200px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.databaseStorageAmount'}/>,
    key: 'databaseStorage',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => currencyFormatter(_get('totalStorageAmount', rowData) || 0),
    width: '250px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.databaseProcessingAndTrackingSessions'}/>,
    key: 'databaseProcessingAndTracking',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => _toNumber(_get('totalTrackingSessions', rowData) || 0) + ` session(s)`,
    width: '300px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.databaseProcessingAndTrackingAmount'}/>,
    key: 'databaseProcessingAndTracking',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) =>  currencyFormatter(_get('totalTrackingAmount', rowData) || 0),
    width: '300px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.splitTestingSessions'}/>,
    key: 'splitTesting',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => _toNumber(_get('totalSplitTestSessions', rowData) || 0) + ` session(s)`,
    width: '200px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.splitTestingAmount'}/>,
    key: 'splitTesting',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) =>  currencyFormatter(_get('totalSplitTestAmount', rowData) || 0),
    width: '200px',
  },
  /*{
    title: <FormattedMessage id={'app.company.usage.table.column.payWowSeatAllocation'}/>,
    key: 'payWowSeatAllocation',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => _toNumber(_get('payWowSeatAllocationCount', rowData) || 0) + ` seat(s) - ` + currencyFormatter(_get('payWowSeatAllocationAmount', rowData) || 0),
    width: '200px',
  },
  {
    title: <FormattedMessage id={'app.company.usage.table.column.transactions'}/>,
    key: 'transactions',
    // eslint-disable-next-line react/display-name
    render: (_, rowData) => currencyFormatter(_get('totalPayWowTransactionAmount', rowData) || 0),
    width: '70px',
  }*/
]

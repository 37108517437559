import React from 'react'
import {PlusOutlined} from '@ant-design/icons'
import Button from 'ctrwowShared/components/ui/Button'
import AddMemberModal from './AddMemberModal'
import {useModalState} from 'ctrwowShared/hooks/useModalState'


const AddMemberLayout = (props) => {
  const [showModal, modalSettings] = useModalState()
  return (<><Button
    className={'dfoMar__half--l'}
    type={'primary'}
    ghost
    size={'large'}
    icon={<PlusOutlined />}
    onClick={showModal}
  >
    Add Member
  </Button>
    <AddMemberModal {...modalSettings} {...props}/>
  </>)
}

AddMemberLayout.propTypes = {}

export default AddMemberLayout




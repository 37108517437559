import React from "react";
import Button from "ctrwowShared/components/ui/Button";
import Space from "ctrwowShared/components/ui/Space";
import { Title, Text } from "ctrwowShared/components/ui/Typography";
import { STATUS_SYNC_CRM, DISABLED_SYNC_WITH_STATUS } from "../../constants";
import { useSyncToCrmAction } from "./syncToCrmAction";
import { get } from "lodash";

const MigrationCRM = ({ company, refreshCompanyList, loadCompanyDetails }) => {
  const syncToCrmCompany = useSyncToCrmAction(company.id, refreshCompanyList || loadCompanyDetails);

  const sharedCRM = get(company, "sharedCRM");
  const crm = get(company, "crm");
  const migrationStatus = get(company, "migrationStatus");
  const isCheckShared = sharedCRM;

  return (
    <Space direction={"vertical"} style={{ width: `100%` }} size={20}>
      <Space direction={"vertical"} size={16}>
        <div>
          <Title level={5}>Status</Title>
          <Text keyboard>{`${STATUS_SYNC_CRM[migrationStatus]}`}</Text>
        </div>
        {isCheckShared && (
          <>
            <div>
              <Title level={5}>Company ID</Title>
              <Text keyboard>{`${sharedCRM.companyId} -> ${crm.companyId}`}</Text>
            </div>
            <div>
              <Title level={5}>xCid</Title>
              <Text keyboard>{`${sharedCRM.xCid} -> ${crm.xCid}`}</Text>
            </div>
          </>
        )}
      </Space>
      {isCheckShared && (
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="secondary"
              size={"large"}
              onClick={syncToCrmCompany}
              disabled={DISABLED_SYNC_WITH_STATUS.includes(migrationStatus)}
            >
              Sync to CRM
            </Button>
          </Space>
        </div>
      )}
    </Space>
  );
};

export default MigrationCRM;

import Logo from 'ctrwowShared/components/shared/Layout/Logo'
import {Sider} from 'ctrwowShared/components/ui/Layout'
import React, {useCallback, useState} from 'react'
import MainMenu from './../MainMenu'
import UserInfo from './../UserInfo'

import styles from './LeftSide.module.scss'
import appStyles from 'app-variables.scss'

const saveToLocal = (val) => val ? localStorage.setItem('ctrAdmin__leftSideStatus__collapsed', true) : localStorage.removeItem('ctrAdmin__leftSideStatus__collapsed')
const getFromLocal = () => localStorage.getItem('ctrAdmin__leftSideStatus__collapsed')


const LeftSide = () => {

  const [collapsed, set_collapsed] = useState(!!getFromLocal())
  const toggleCollapsed = useCallback(() =>{
    const newVal = !collapsed
    set_collapsed(newVal)
    saveToLocal(newVal)
  }, [collapsed])


  return (
    <Sider
      collapsible
      className={styles.ctrComp}
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      collapsedWidth={appStyles.ctrLayoutSidebarCollapsedWidth}
      width={appStyles.ctrLayoutSidebarWidth}
    >
      <Logo isMini={collapsed} className={styles.ctrComp_logo}/>
      <MainMenu isMini={collapsed} className={styles.ctrComp_mainMenu}/>
      <UserInfo isMini={collapsed}/>
    </Sider>
  )
}

LeftSide.propTypes = {}

export default LeftSide




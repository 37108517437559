import {addUserListByCompanyFetcher} from 'fetchers/company.fetchers'
import React, {useCallback} from 'react'
import _uniqueId from 'lodash/fp/uniqueId'
import Button from 'ctrwowShared/components/ui/Button'
import {ControlledInput} from 'ctrwowShared/components/ui/Input'
import Form from 'ctrwowShared/components/ui/Form'
import useFormValidateProps from 'ctrwowShared/components/ui/Form/useFormValidatieProps'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {useFieldArray, useForm} from 'react-hook-form'
import UserRoleSelector from 'ctrwowShared/components/shared/CommonSelectors/UserRoleSelector'
import {PERMISSION_ROLES} from 'ctrwowShared/constants/user'
import {yupResolver} from '@hookform/resolvers/yup'
import Space from 'ctrwowShared/components/ui/Space'
import {useDispatch} from 'react-redux'
import {validationSchema} from './validationSchema'
import {setNotify, setGlobalLoading} from 'appActions'
import styles from './AddMemberForm.module.scss'


const UserItem = ({user: {_ctrTempId, email, role}, index, update, remove, canDelete, getErrorStatus}) => {
  const onChange = useCallback(email => update(index, {email, role, _ctrTempId}), [update, index, role, _ctrTempId])

  return <Form.Item {...getErrorStatus(`userList[${index}].email`)}>
    <div className={styles.ctrComp__userItem}>
      <ControlledInput
        placeholder={'User email'}
        value={email}
        onChange={onChange}
      />
      <UserRoleSelector role={role} setRole={(role) => update(index, {_ctrTempId, email, role})}/>
      {canDelete
        ? <div className={styles.ctrComp__userItem__del}><MinusCircleOutlined onClick={() => remove(index)}/></div>
        : null}
    </div>
  </Form.Item>
}

const InviteUsersContent = ({hideModal, companyId, onSuccess}) => {
  const dispatch = useDispatch()

  const getInitData = useCallback(() => ({role: PERMISSION_ROLES.MEMBER, _ctrTempId: _uniqueId('userItem_')}), [])

  const {formState: {errors}, handleSubmit, control} = useForm({
    defaultValues: {userList: [getInitData()]},
    resolver: yupResolver(validationSchema)
  })

  const getErrorStatus = useFormValidateProps(errors)

  const {fields: userList, append, remove, update} = useFieldArray({
    control,
    name: 'userList'
  })

  const onAddNew = useCallback(() => append(getInitData()), [append, getInitData])

  const sendInviteRequest = useCallback(async ({userList}) => {
    try {
      dispatch(setGlobalLoading(true))
      await addUserListByCompanyFetcher(companyId, userList.map(({email, role}) => ({email, role})))
      hideModal()
      onSuccess()
    } catch (e) {
      setNotify('error', e)
    } finally {
      dispatch(setGlobalLoading(false))

    }
  }, [dispatch, companyId, hideModal, onSuccess])

  return (
    <Form autoComplete="off" layout={'horizontal'}>
      <label className={'dfoMar__double--b'}>Members</label>
      {userList.map(
        (user, index) =>
          (<UserItem key={user._ctrTempId || index}
                     user={user} index={index}
                     {...{update, remove, getErrorStatus}}
                     canDelete={(userList || []).length > 1}/>)
      )}
      <Form.Item>
        <Button type="dashed" style={{width: '100%', marginLeft: 0}} onClick={onAddNew} block icon={<PlusOutlined/>}> Add a member</Button>
      </Form.Item>

      <Form.Item className={'dfoTextAlign--right dfoMar__none'}>
        <Space>
          <Button type={'primary'} ghost onClick={hideModal} size={'large'}>Cancel</Button>
          <Button type={'primary'} htmlType="submit" size={'large'}
                  onClick={handleSubmit(sendInviteRequest)}>Invite</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default InviteUsersContent

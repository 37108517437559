import ContentWrapper from 'ctrwowShared/components/shared/ContentWrapper'
import React, {useCallback} from 'react'
import GeneralInfoForm from 'ctrwowShared/components/shared/Company/GeneralInfo'
import {useDispatch, useSelector} from 'react-redux'
// do not support to delete company
// import AddedButtons from './AddedButtons'
import {actions, stateManager} from './store'


const GeneralInfo = ({refreshCompanyList, company}) => {
  const dispatch = useDispatch()
  const companyInfo = useSelector(stateManager.getCompany) || company
  const uiState = useSelector(stateManager.getCompanyUiStatus) || {}
  const onSubmit = useCallback((values) => {
    dispatch(actions.updateDetails(values, refreshCompanyList))
  }, [refreshCompanyList, dispatch])




  return <ContentWrapper {...uiState}>
    {companyInfo && <GeneralInfoForm canEdit={true} onSubmit={onSubmit} companyInfo={companyInfo} formGrid={{lg: 24}}/>}
  </ContentWrapper>
}

GeneralInfo.propTypes = {}

export default GeneralInfo




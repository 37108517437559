import {MODULE_STATE_NAME} from './constants'

// Actions
export const INIT_DATA = `${MODULE_STATE_NAME}/INIT-DATA`
export const RESET_DATA = `${MODULE_STATE_NAME}/RESET-DATA`
export const LOAD_CONFIG = `${MODULE_STATE_NAME}/LOAD-CONFIG`
export const LOAD_CONFIG_ERROR = `${MODULE_STATE_NAME}/LOAD-CONFIG-ERROR`
export const LOAD_CONFIG_SUCCESS = `${MODULE_STATE_NAME}/LOAD-CONFIG-SUCCESS`
export const SAVE_CONFIG = `${MODULE_STATE_NAME}/SAVE-CONFIG`
export const SAVE_CONFIG_ERROR = `${MODULE_STATE_NAME}/SAVE-CONFIG-ERROR`
export const SAVE_CONFIG_SUCCESS = `${MODULE_STATE_NAME}/SAVE-CONFIG-SUCCESS`

import React from 'react'
import Modal from 'ctrwowShared/components/ui/Modal'

import PropTypes from 'prop-types'
import appStyles from 'app-variables.scss'
import AddMemberForm from './AddMemberForm'

const OrderStatusModal = ({isShowModal, hideModal, ...rest}) => {
  return (
    <Modal
      title={'Add member'}
      width={appStyles.ctrModalSize_sm}
      onCancel={hideModal}
      visible={isShowModal}
      footer={null}
    >
      <AddMemberForm hideModal={hideModal} {...rest}/>
    </Modal>)
}
OrderStatusModal.propTypes = {
  isShowModal: PropTypes.bool,
  hideModal: PropTypes.func,
  schemaId: PropTypes.any,
  onUpdateDetailsSuccess: PropTypes.func, //use for both [add new & edit details]
  settings: PropTypes.any
}

export default OrderStatusModal

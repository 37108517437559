/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import {reducer as globalReducer, MODULE_STATE_NAME} from './modules/AppCore';
import {reducer as languageProviderReducer} from './components/shared/LanguageProvider';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    [MODULE_STATE_NAME]: globalReducer,
    language: languageProviderReducer,

    ...injectedReducers,
  });

  return rootReducer;
}

import * as types from './types'

// Action Creators
export function initData(company) {
  return { type: types.INIT_DATA, payload:{company}}
}

export function resetData() {
  return { type: types.RESET_DATA}
}

export function setCompany(companyDetails, companyId) {
  return { type: types.SET_COMPANY, payload: {companyDetails, companyId}}
}

export function loadDetails() {
  return { type: types.LOAD_DETAILS}
}

export function loadDetailsError(error) {
  return { type: types.LOAD_DETAILS_ERROR, payload: { error }}
}

export function loadDetailsSuccess(companyDetails) {
  return { type: types.LOAD_DETAILS_SUCCESS, payload: { companyDetails }}
}

export function updateDetails(companyDetails, onSuccess) {
  return { type: types.UPDATE_DETAILS, payload: {companyDetails, onSuccess}}
}

export function updateDetailsError(error) {
  return { type: types.UPDATE_DETAILS_ERROR, payload: { error }}
}

export function updateDetailsSuccess(companyDetails) {
  return { type: types.UPDATE_DETAILS_SUCCESS, payload: { companyDetails }}
}

export function deleteCompany(company, onDeleteSuccess) {
  return { type: types.DELETE_COMPANY, payload: {company, onDeleteSuccess}}
}

export function deleteCompanyError(error) {
  return { type: types.DELETE_COMPANY_ERROR, payload: { error }}
}

export function deleteCompanySuccess(response) {
  return { type: types.DELETE_COMPANY_SUCCESS, payload: { response }}
}

export function resetUsers() {
  return { type: types.RESET_USERS}
}

export function loadUsers(listConfig) {
  return { type: types.LOAD_USERS, payload: {listConfig}}
}

export function loadUsersError(error) {
  return { type: types.LOAD_USERS_ERROR, payload: { error }}
}

export function loadUsersSuccess(userList) {
  return { type: types.LOAD_USERS_SUCCESS, payload: { userList }}
}

export function addUser(emails) {
  return { type: types.ADD_USER, payload: {emails}}
}

export function addUserError(error) {
  return { type: types.ADD_USER_ERROR, payload: { error }}
}

export function addUserSuccess(response) {
  return { type: types.ADD_USER_SUCCESS, payload: { response }}
}

export function deleteUser(user) {
  return { type: types.DELETE_USER, payload: {user}}
}

export function deleteUserError(error) {
  return { type: types.DELETE_USER_ERROR, payload: { error }}
}

export function deleteUserSuccess(response) {
  return { type: types.DELETE_USER_SUCCESS, payload: { response }}
}

export function updateUser(userDetails, onSuccess) {
  return { type: types.UPDATE_USER, payload: {userDetails, onSuccess}}
}

export function updateUserError(error) {
  return { type: types.UPDATE_USER_ERROR, payload: { error }}
}

export function updateUserSuccess(response) {
  return { type: types.UPDATE_USER_SUCCESS, payload: { response }}
}


import {useFormik} from 'formik'
import React, {useEffect, useMemo} from 'react'
import Form, {getValidateStatus} from 'ctrwowShared/components/ui/Form'
import {convertFromObjToString, convertFromStringToObj} from 'ctrwowShared/utils/parseJson'
import {TextArea} from 'ctrwowShared/components/ui/Input'
import Button from 'ctrwowShared/components/ui/Button'
import ClaimsConfig from 'components/shared/ClaimsConfig'
import TagsConfig from 'components/shared/TagsConfig'
import * as companyDomainData from 'ctrwowShared/domainData/company.domainData'
import {useDispatch} from 'react-redux'
import injectStore, {actions} from './store'
import {validationSchema} from './validationSchema'


const AdvanceSettings = ({company, refreshCompanyList}) => {
  const dispatch = useDispatch()

  const {errors, touched, values, resetForm, setFieldValue, dirty,handleBlur, handleChange,handleSubmit} = useFormik({
    initialValues: {
      claims: companyDomainData.getClaims(company),
      tags: companyDomainData.getTags(company),
      featureConfigs: convertFromObjToString(companyDomainData.getFeatureConfig(company) || undefined, undefined, false, null, '\t'),
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({featureConfigs, ...values}) => {
      console.log(values)
      values.features = convertFromStringToObj(featureConfigs, null) || {}
      dispatch(actions.saveConfig(values, refreshCompanyList))
    }
  })
  const getValidateStatusCb = useMemo(() => getValidateStatus(touched, errors), [touched, errors])

  useEffect(() => {
    dispatch(actions.loadConfig())
    return () => dispatch(actions.resetData())
  }, [dispatch])

  return (<Form layout="vertical">
    <Form.Item label={'CLAIMS config'}>
      <ClaimsConfig name='claims' onChange={setFieldValue} value={values.claims}/>
    </Form.Item>
    <Form.Item label={'Tags'}>
      <TagsConfig name='tags' onChange={setFieldValue} value={values.tags}/>
    </Form.Item>
    <Form.Item label={'Feature Configs'} {...getValidateStatusCb('featureConfigs')}>
      <TextArea rows={15} name='featureConfigs' value={values.featureConfigs} onChange={handleChange} onBlur={handleBlur}/>
    </Form.Item>
    <div style={{textAlign: 'right'}}>
      <Button
        disabled={!dirty}
        type='primary' ghost size='large'
        className={'dfoMar__half--r'}
        onClick={resetForm}>Reset</Button>
      <Button
        type='primary'
        size={'large'}
        disabled={!dirty}
        onClick={handleSubmit}>Save</Button>
    </div>
  </Form>)

}

AdvanceSettings.propTypes = {}

export default injectStore(AdvanceSettings)




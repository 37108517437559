import moment from 'moment'

export function formatStartDateFilterTimeZoneStr(startDate) {
  const localHour = moment().get('h');
  const localMinute = moment().get('m');
  const localSecond = moment().get('s');
  return startDate.clone()
    .set('h', localHour)
    .set('m', localMinute)
    .set('s', localSecond)
    .startOf('day').toISOString();
}

export function formatEndDateFilterTimeZoneStr(endDate, timeZone) {
  const localHour = moment().get('h');
  const localMinute = moment().get('m');
  const localSecond = moment().get('s');
  return endDate.clone()
    .set('h', localHour)
    .set('m', localMinute)
    .set('s', localSecond)
    .endOf('day').toISOString();
}
import {MODULE_STATE_NAME} from './constants'

// Actions
export const INIT_DATA = `${MODULE_STATE_NAME}/INIT-DATA`
export const RESET_DATA = `${MODULE_STATE_NAME}/RESET-DATA`
export const LOAD_DETAILS = `${MODULE_STATE_NAME}/LOAD-DETAILS`
export const SET_COMPANY = `${MODULE_STATE_NAME}/SET-COMPANY`
export const LOAD_DETAILS_ERROR = `${MODULE_STATE_NAME}/LOAD-DETAILS-ERROR`
export const LOAD_DETAILS_SUCCESS = `${MODULE_STATE_NAME}/LOAD-DETAILS-SUCCESS`
export const UPDATE_DETAILS = `${MODULE_STATE_NAME}/UPDATE-DETAILS`
export const UPDATE_DETAILS_ERROR = `${MODULE_STATE_NAME}/UPDATE-DETAILS-ERROR`
export const UPDATE_DETAILS_SUCCESS = `${MODULE_STATE_NAME}/UPDATE-DETAILS-SUCCESS`
export const DELETE_COMPANY = `${MODULE_STATE_NAME}/DELETE-COMPANY`
export const DELETE_COMPANY_ERROR = `${MODULE_STATE_NAME}/DELETE-COMPANY-ERROR`
export const DELETE_COMPANY_SUCCESS = `${MODULE_STATE_NAME}/DELETE-COMPANY-SUCCESS`
export const RESET_USERS = `${MODULE_STATE_NAME}/RESET-USERS`
export const LOAD_USERS = `${MODULE_STATE_NAME}/LOAD-USERS`
export const LOAD_USERS_ERROR = `${MODULE_STATE_NAME}/LOAD-USERS-ERROR`
export const LOAD_USERS_SUCCESS = `${MODULE_STATE_NAME}/LOAD-USERS-SUCCESS`
export const ADD_USER = `${MODULE_STATE_NAME}/ADD-USER`
export const ADD_USER_ERROR = `${MODULE_STATE_NAME}/ADD-USER-ERROR`
export const ADD_USER_SUCCESS = `${MODULE_STATE_NAME}/ADD-USER-SUCCESS`
export const DELETE_USER = `${MODULE_STATE_NAME}/DELETE-USER`
export const DELETE_USER_ERROR = `${MODULE_STATE_NAME}/DELETE-USER-ERROR`
export const DELETE_USER_SUCCESS = `${MODULE_STATE_NAME}/DELETE-USER-SUCCESS`
export const UPDATE_USER = `${MODULE_STATE_NAME}/UPDATE-USER`
export const UPDATE_USER_ERROR = `${MODULE_STATE_NAME}/UPDATE-USER-ERROR`
export const UPDATE_USER_SUCCESS = `${MODULE_STATE_NAME}/UPDATE-USER-SUCCESS`

import _startsWith from "lodash/fp/startsWith";
import _find from "lodash/fp/find";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import BillingUsageByCycle from "./BillingUsageByCycle";
import BillingUsageOverview from "./BillingUsageOverview/BillingUsageOverviewLayout";
import React, { useMemo, useCallback } from "react";
import Menu from "ctrwowShared/components/ui/Menu";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const SUB_ROUTES = {
  ByCycle: {
    path: "/billing-usage/cycle",
    component: BillingUsageByCycle,
    title: "View by billing cycle",
  },
  ByCustomDateRange: {
    path: "/billing-usage/custom-date-range",
    component: BillingUsageOverview,
    title: "View by custom date range",
  },
};

const BillingLayout = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const activeItem = useMemo(() => {
    const found = _find(({ path }) => _startsWith(path)(pathname))(SUB_ROUTES);

    return found ? found.path : SUB_ROUTES.ByCycle.path;
  }, [pathname]);

  const onClick = useCallback(({ key }) => dispatch(push(key)), []);

  return (
    <div>
      <Menu
        mode="horizontal"
        size="large"
        className={"dfoContentSwitcherTag"}
        onClick={onClick}
        selectedKeys={activeItem.path ? [activeItem.path] : pathname}
        style={{ backgroundColor: "transparent", marginBottom: "2rem", fontSize: "1.1rem"}}
      >
        <Menu.Item key={SUB_ROUTES.ByCycle.path}>
          View by billing cycle
        </Menu.Item>

        <Menu.Item key={SUB_ROUTES.ByCustomDateRange.path}>
          View by custom date range
        </Menu.Item>
      </Menu>

      <Switch>
        <Route {...SUB_ROUTES.ByCycle} />
        <Route {...SUB_ROUTES.ByCustomDateRange} />
        <Redirect to={SUB_ROUTES.ByCycle.path} />
      </Switch>
    </div>
  );
};

BillingLayout.propTypes = {};

export default BillingLayout;

import CompanyOverviewLayout from './CompanyOverviewLayout.Loadable'


const COMPANY_PAGES = {
  path: `/company`,
  exact: true,
  component: CompanyOverviewLayout,
  layoutConfig: {
    title: 'Companies',
    routes: [{breadcrumbName: 'Companies'}]
  }
}

export default COMPANY_PAGES

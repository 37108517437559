/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import _map from 'lodash/fp/map'
import React, {useMemo} from 'react'
import {Switch, Route} from 'react-router-dom'

import NotFoundPage, {NoPermissionLayout} from 'modules/CommonPages'
import {modulesRoutes} from 'modules/routes'

import PrivatePage from './PrivatePage'
import RouteWrapper from './RouteWrapper'
import ToastMessage from 'ctrwowShared/components/shared/ToastMessage'
import DocumentHead from './DocumentHead'
import GlobalLoading from './GlobalLoading'


export default function App() {

  const noPermissionLayoutConfig = useMemo(() => ({isUsedAppShell:false}), [])
  const privateRoutes = useMemo(() => _map(
    (route) => <RouteWrapper key={route.path}  {...route}/>,
    modulesRoutes.privatePages)
    , [])

  return (
    <React.Fragment>
      <DocumentHead/>
      <div>
        {/*<PublicPage>*/}
        {/*  <Switch>*/}
        {/*    {_map(*/}
        {/*      ({component: Component, ...route}) => <RouteWrapper key={route.path} component={Component} {...route}/>,*/}
        {/*      modulesRoutes.publicPages)}*/}
        {/*  </Switch>*/}
        {/*</PublicPage>*/}
        <PrivatePage>
          <Switch>
            <RouteWrapper path="/no-permission" exact={true} layoutConfig={noPermissionLayoutConfig} component={NoPermissionLayout}/>
            {privateRoutes}
            <Route path="*" component={NotFoundPage}/>
          </Switch>
        </PrivatePage>
      </div>
      <GlobalLoading/>
      <ToastMessage/>
    </React.Fragment>
  )
}

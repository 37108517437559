import _isUndefined from 'lodash/fp/isUndefined'
import _getOr from 'lodash/fp/getOr'
import _get from 'lodash/fp/get'
import _pipe from 'lodash/fp/pipe'
import _setFp from 'lodash/fp/set'
import _trim from 'lodash/fp/trim'

import {createSelector} from 'reselect'
import produce from 'immer'
import {MODULE_STATE_NAME} from './constants'

// convert to mutable
const _set = _setFp.convert({'immutable': false})

const STATE_DEFAULT_VALUE = {
  company: undefined,
  companyId: undefined,
  companyDetails: undefined,
  users: {
    listConfig: {},
    searchResult: undefined
  },
  uiState: undefined
}

export const initState = () => ({...STATE_DEFAULT_VALUE})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
export const getLocalState = (state) => _get([MODULE_STATE_NAME], state) || initState()
// const getLocalState = _getOr(initState(), MODULE_STATE_NAME)

export const getCompany = createSelector(
  getLocalState,
  _get('company')
)

export const getCompanyId = createSelector(
  getLocalState,
  _get('companyId')
)

export const getCompanyUiStatus = createSelector(
  getLocalState,
  _get('uiState.loadDetails')
)

export const getUserList = createSelector(
  getLocalState,
  _getOr({}, 'users.searchResult'))

export const getUserListConfig = createSelector(
  getLocalState,
  _getOr({}, 'users.listConfig'))

export const getUserListConfigParams = createSelector(
  getUserListConfig,
  ({queryString, listSettings: {pagination: {current, pageSize} = {}, sorter: {columnKey, order} = {}} = {}}) => ({
    filterValue: _trim(queryString),
    page: current - 1, count: pageSize,
    ...(order && {sortField: columnKey, isDesc: order === 'descend'})
  })
)

export const getUserListUiStatus = createSelector(
  getLocalState,
  _getOr({}, 'uiState.userList')
)

// export const getUserListParams = createSelector(
//   getLocalState,
//   _getOr({}, 'uiState.userList')
// )

/******************************** UPDATER ********************************/
export const updaterCompanyDetails = (state, {companyDetails, error, isLoading, companyId}) => produce(state, _pipe([
  ...(companyDetails ? [
    _set('company', companyDetails),
    _set('companyId', companyDetails.id),
    _set('users', {...STATE_DEFAULT_VALUE.users})
  ] : []),
  ...(companyId ? [_set('companyId', companyId)] : []),
  _set('uiState.loadDetails', isLoading || error ? ({error, isLoading}) : undefined)
]))

export const resetUserList = state => produce(state, _pipe([
  _set('users', {...STATE_DEFAULT_VALUE.users}),
  _set('uiState.userList', undefined)
]))

export const updaterUserList = (state, {companyId, userList, isLoading, error, listConfig = {}}) => produce(state, _pipe([
  ...(!_isUndefined(listConfig.queryString) ? [_set('users.listConfig.queryString', listConfig.queryString)] : []),
  ...(listConfig.listSettings ? [_set('users.listConfig.listSettings', listConfig.listSettings)] : []),

  ...(companyId ? [_set('companyId', companyId)] : []),
  ...(userList ? [_set('users.searchResult', userList)] : []),
  _set('uiState.userList', isLoading || error ? ({error, isLoading}) : undefined)
]))

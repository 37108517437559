/* eslint-disable no-template-curly-in-string */
import {defineMessages} from 'ctrwowShared/utils/ctrwow-translation.utils'


export default defineMessages({
  mixed_default: {
    id: 'app.common.validation.default',
    defaultMessage: 'This field is invalid'
  },
  mixed_required: {
    id: 'app.common.validation.mixed.required',
    defaultMessage: 'This field is a required field'
  },
  mixed_oneOf: {
    id: 'app.common.validation.mixed.oneOf',
    defaultMessage: 'This field must be one of the following values: ${values}'
  },
  mixed_notOneOf: {
    id: 'app.common.validation.mixed.notOneOf',
    defaultMessage: 'This field must be one of the following values: ${values}'
  },
  mixed_defined: {
    id: 'app.common.validation.mixed.defined',
    defaultMessage: 'This field must be defined'
  },
  string_required: {
    id: 'app.common.validation.string.required',
    defaultMessage: 'This field is a required field'
  },
  string_length: {
    id: 'app.common.validation.string.length',
    defaultMessage: 'This field must be exactly ${length} characters'
  },
  string_min: {
    id: 'app.common.validation.string.min',
    defaultMessage: 'This field must be at least ${min} characters'
  },
  string_max: {
    id: 'app.common.validation.string.max',
    defaultMessage: 'This field must be at most ${max} characters'
  },
  string_matches: {
    id: 'app.common.validation.string.matches',
    defaultMessage: 'This field must match the following: "${regex}"'
  },
  string_email: {
    id: 'app.common.validation.string.email',
    defaultMessage: 'This field must be a valid email'
  },
  string_url: {
    id: 'app.common.validation.string.url',
    defaultMessage: 'This field must be a valid URL'
  },
  string_uuid: {
    id: 'app.common.validation.string.uuid',
    defaultMessage: 'This field must be a valid UUID'
  },
  string_trim: {
    id: 'app.common.validation.string.trim',
    defaultMessage: 'This field must be a trimmed string'
  },
  string_lowercase: {
    id: 'app.common.validation.string.lowercase',
    defaultMessage: 'This field must be a lowercase string'
  },
  string_uppercase: {
    id: 'app.common.validation.string.uppercase',
    defaultMessage: 'This field must be a upper case string'
  },
  number_min: {
    id: 'app.common.validation.number.min',
    defaultMessage: 'This field must be greater than or equal to ${min}'
  },
  number_max: {
    id: 'app.common.validation.number.max',
    defaultMessage: 'This field must be less than or equal to ${max}'
  },
  number_lessThan: {
    id: 'app.common.validation.number.lessThan',
    defaultMessage: 'This field must be less than ${less}'
  },
  number_moreThan: {
    id: 'app.common.validation.number.moreThan',
    defaultMessage: 'This field must be greater than ${more}'
  },
  number_positive: {
    id: 'app.common.validation.number.positive',
    defaultMessage: 'This field must be a positive number'
  },
  number_negative: {
    id: 'app.common.validation.number.negative',
    defaultMessage: 'This filed must be a negative number'
  },
  number_integer: {
    id: 'app.common.validation.number.integer',
    defaultMessage: 'This field must be an integer'
  },
  date_min: {
    id: 'app.common.validation.date.min',
    defaultMessage: 'This field must be later than ${min}'
  },
  date_max: {
    id: 'app.common.validation.date.max',
    defaultMessage: 'This field field must be at earlier than ${max}'
  },
  boolean_isValue: {
    id: '.boolean.isValue',
    defaultMessage: 'This field must be ${value}'
  },
  object_noUnknown: {
    id: 'app.common.validation.object.noUnknown',
    defaultMessage: 'This field has unspecified keys: ${unknown}'
  },
  array_min: {
    id: 'app.common.validation.array.min',
    defaultMessage: 'This field must have at least ${min} items'
  },
  array_max: {
    id: 'app.common.validation.array.max',
    defaultMessage: 'This field must have less than or equal to ${max} items'
  },
  array_required: {
    id: 'app.common.validation.array.required',
    defaultMessage: 'This field is a required field'
  },
  array_length: {
    id: 'app.common.validation.array.length',
    defaultMessage: 'This must have ${length} items'
  }
})


import {PRICING_PACKAGES} from 'ctrwowShared/constants/pricingPackages'
const PRICING_PACKAGES_CLONE = {...PRICING_PACKAGES}

export const SUBSCRIPTION_PLAN_TYPE = {
    [`1`]: `Monthly`,
    [`2`]: `Yearly`
}

PRICING_PACKAGES_CLONE.BRONZE.value = PRICING_PACKAGES_CLONE.BRONZE.value === `bronze` ? `free` : PRICING_PACKAGES_CLONE.BRONZE.value
//PRICING_PACKAGES_CLONE.BRONZE.name = "Free"

let PACKAGES_LABEL_VALUE = {}
Object.values(PRICING_PACKAGES_CLONE).map(item => {
    PACKAGES_LABEL_VALUE = {...PACKAGES_LABEL_VALUE, [item.value]: item.groupName ? `${item.groupName} - ${item.name}` : item.name}
    return true
})
export const PACKAGES_LABEL_VALUE_MAPPING = PACKAGES_LABEL_VALUE

export const LIST_DISABLED_SUBSCRIPTION_PLAN_TYPE = [
    PRICING_PACKAGES_CLONE.BRONZE.value,
    PRICING_PACKAGES_CLONE.SILVER.value,
    PRICING_PACKAGES_CLONE.GOLD.value,
]

export const PACKAGES = PRICING_PACKAGES_CLONE || {}

export const VALUE_STATUS_SYNC_CRM = {
    NOT_START: 0,
    IN_PROCESS: 1,
    SUCCESSED: 2,
    FAILED : 3,
}

export const STATUS_SYNC_CRM = {
    [VALUE_STATUS_SYNC_CRM.NOT_START]: `Not Start`,
    [VALUE_STATUS_SYNC_CRM.IN_PROCESS]: `In process`,
    [VALUE_STATUS_SYNC_CRM.SUCCESSED]: `Successed`,
    [VALUE_STATUS_SYNC_CRM.FAILED]: `Failed`,
}

export const DISABLED_SYNC_WITH_STATUS = [VALUE_STATUS_SYNC_CRM.IN_PROCESS]

import {warning} from 'ctrwowShared/components/ui/Modal'
import _get from 'lodash/fp/get'
import _each from 'lodash/fp/each'
import {removeUserFromStorage} from 'modules/AppCore/store/syncUserToStorage'
import getDefaultPage from 'utils/getDefaultPage'

// this is used as a flag to show only one confirm-logout error
let isShowingLogoutConfirm = false

function forceLogout() {
  // const resetUserFlow = () => appStore.dispatch(resetUser())
  const resetUserFlow = () => {
    removeUserFromStorage()
    window.location.replace(getDefaultPage())
  }
  try {
    isShowingLogoutConfirm = true
    _each((elm) => {
      elm.style.visibility = 'hidden'
    }, document.getElementsByClassName('Toastify'))

    return warning({
      title: 'Your session has been expired, please login to continue.',
      okText: 'Ok',
      onOk: resetUserFlow,
      zIndex: 1500
    })
  } catch (e) {
    console.log(e)
    resetUserFlow()
  }
}

export const responseHandler = (options, response) => {
  if (response.status === 401 || response.status === 403) {
    const isLogout = async function() {
      return response.text ? await response.text().then(function(text) {
        try {
          const message = JSON.parse(text)
          return message.name && message.name.toString().toLowerCase().includes('token')
        } catch (e) {
          console.warn(e)
        }
        return false
      }) : false
    }
    if (!isShowingLogoutConfirm && isLogout()) {
      forceLogout()
      // eslint-disable-next-line no-throw-literal
      throw 'Session Expired'
    }
  }
  else if (response.status === 422 && _get('method', options) === 'GET') {

    if (!response.text) {
      return response
    }

    return response.text().then((body) => {
      try {
        body = JSON.parse(body)

        // NO-PERMISSION >> redirect to default page (dashboard page)
        if (body && !body.isSuccess && body.errors[0].code === 403) {
          window.location.replace(getDefaultPage())
        } else {
          return response
        }
      } catch (e) {
        return response
      }
    })
  }
  return response
}

const CONFIGURABLE_CONSTANTS = {
  CTRWOW_ADMIN_ENV: process.env.CTRWOW_ADMIN_ENV,
  CTRWOW_ADMIN_SSO_BASE_URL: process.env.CTRWOW_ADMIN_SSO_BASE_URL,
  CTRWOW_ADMIN_SSO_APP_ID: process.env.CTRWOW_ADMIN_SSO_APP_ID,
  CTRWOW_ADMIN_SSO_API_URL: process.env.CTRWOW_ADMIN_SSO_API_URL,

  CTRWOW_ADMIN_BASE_URL: process.env.CTRWOW_ADMIN_BASE_URL
}

export default CONFIGURABLE_CONSTANTS

import UserOverviewLayout from './Loadable'


const USER_OVERVIEW = {
  path: `/users`,
  exact: true,
  component: UserOverviewLayout,
  layoutConfig: {
    title: 'Users Management',
    routes: [{breadcrumbName: 'Users Management'}]
  }
}

export default USER_OVERVIEW

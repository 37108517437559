import React, {useCallback} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {DownloadOutlined} from "@ant-design/icons";
import Button from "ctrwowShared/components/ui/Button";
import Tooltip from "ctrwowShared/components/ui/Tooltip";
import {actions, stateManager} from '../store'

const ExportData = () => {
  const dispatch = useDispatch()
  const {quantity} = useSelector(stateManager.getDataList)
  const onExport = useCallback(() => dispatch(actions.exportData()), [dispatch])
  return <Tooltip title={"Export to csv"}><Button disabled={quantity <= 0} onClick={onExport} icon={<DownloadOutlined />} /></Tooltip>
}

export default ExportData
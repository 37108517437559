import Announcements from './Loadable'


const USER_OVERVIEW = {
  path: `/announcements`,
  exact: true,
  component: Announcements,
  layoutConfig: {
    title: 'Announcements',
    routes: [{breadcrumbName: 'Announcements'}]
  }
}

export default USER_OVERVIEW

import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {DateRangePicker} from 'ctrwowShared/components/ui/DatePicker'
import {stateManager, actions} from "../store"
const DateFilter = () => {
  const dateRanger = useSelector(stateManager.getDateRanger)
  const dispatch = useDispatch()
  const onChangeDateRange = (value) => dispatch(actions.updateDateRange(value))

  return (
    <DateRangePicker
      showTime
      onChange={onChangeDateRange}
      placeholder={['Start Date', 'End Date']}
      size="large"
      format="DD/MM/YYYY"
      allowClear={false}
      value={dateRanger}
      style={{maxWidth: '300px', marginRight: '5px'}}
    />
  )
}

export default DateFilter
import {AlertErrorCard} from 'ctrwowShared/components/ui/Alert'
import {useDispatch, useSelector} from 'react-redux'
import React, {useMemo, useCallback} from 'react'
import {stateManager, actions} from './../store'
import {GridView} from 'ctrwowShared/components/shared/User/UserList'


const SearchResultSection = () => {
  const dispatch = useDispatch()
  const {isLoading, error} = useSelector(stateManager.getUserListUiStatus)
  const {listSettings} = useSelector(stateManager.getUserListConfig) || {}
  const userList = useSelector(stateManager.getUserList)
  const onChangeListSettings = useCallback((pagination, filters, sorter) => {
    dispatch(actions.loadUsers({listSettings: {pagination, sorter}}))
  }, [dispatch])

  const userActions = useMemo(() => ({
    deleteUser: (user) => dispatch(actions.deleteUser(user)),
    editUser: (user, onSuccess) => {
      user.status = user.isActive;
      delete user.isActive
      dispatch(actions.updateUser(user, onSuccess))
      return Promise.resolve(true)
    }
  }), [dispatch])

  if (error) {
    return <AlertErrorCard error={error}/>
  }

  return (
    <GridView
      loading={isLoading}
      userList={userList || {}}
      pagination={listSettings && listSettings.pagination ? listSettings.pagination : listSettings}
      onChange={onChangeListSettings}
      scroll={{x: '50vw'}}
      actions={userActions}
    />
  )
}

SearchResultSection.propTypes = {}

export default SearchResultSection




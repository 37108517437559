import {MODULE_STATE_NAME} from './constants'

// Actions
export const INIT_DATA = `${MODULE_STATE_NAME}/INIT-DATA`
export const RESET_DATA = `${MODULE_STATE_NAME}/RESET-DATA`
export const LOAD_LIST = `${MODULE_STATE_NAME}/LOAD-LIST`
export const LOAD_LIST_SUCCESS = `${MODULE_STATE_NAME}/LOAD-LIST-SUCCESS`
export const UPDATE_QUERY_STRING = `${MODULE_STATE_NAME}/UPDATE-QUERY-STRING`
export const UPDATE_DATA_LIST_SETTINGS = `${MODULE_STATE_NAME}/UPDATE-DATA-LIST-SETTINGS`
export const EXPORT_COMPANY = `${MODULE_STATE_NAME}/EXPORT_COMPANY`
export const UPDATE_DATE_RANGE = `${MODULE_STATE_NAME}/UPDATE-DATE-RANGE`

import React from 'react'
import AppShellLayout from 'components/shared/AppShell/AppShellLayout'

import Authentication from './Authentication'


const PrivatePage = ({children}) => (
  <Authentication>
    <AppShellLayout>
      {children}
    </AppShellLayout>
  </Authentication>)

PrivatePage.propTypes = {}

export default PrivatePage




import SystemOverviewLayout from './Loadable'


const SYSTEM_OVERVIEW = {
  path: `/system`,
  exact: true,
  component: SystemOverviewLayout,
  layoutConfig: {
    title: 'System Settings',
    routes: [{breadcrumbName: 'System Settings'}]
  }
}

export default SYSTEM_OVERVIEW

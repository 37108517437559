/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Route} from 'react-router-dom'
import {actions} from './store'


const RouteWrapper = ({layoutConfig, ...props}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.setLayoutConfig(layoutConfig || {}))
  },[])

  return (<Route {...props}/>)
}


export default RouteWrapper

// import ExternalLanderLayout from './ExternalLanderLayout.Loadable'
// import WidgetOverviewLayout from './WidgetOverviewLayout'
import WidgetOverviewLayout from './WidgetOverviewLayout.Loadable'


const EXTERNAL_LANDER_PAGES = {
  path: `/widget`,
  exact: true,
  component: WidgetOverviewLayout,
  layoutConfig: {
    title: 'Widgets',
    routes: [{breadcrumbName: 'Widgets'}]
  }
}

export default EXTERNAL_LANDER_PAGES
